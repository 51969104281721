.autocompleteWrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .suggestionWrapper {
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    list-style: none;
    margin: 0 auto;
    display: block;
    padding: 4px;
    position: absolute;
    z-index: 900;
    background-color: #fff;
  }
  
  .suggestionWrapper > li {
    padding: 8px 4px;
    margin: 4px 0;
    border-bottom: 1px solid #000;
    cursor: pointer;
  }