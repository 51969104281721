/****** UTILS ******/
@import "utils/variables";
@import "utils/mixins";

/******* BASE *******/
@import "base/typography";
@import "base/colors"; 
@import "base/base";
@import "base/grid";

/******* COMPONENTS ******/
@import "components/button";

/****** LAYOUT ******/
@import "layout/avatar";
@import "layout/bootstrap-classes";
@import "layout/breadcrumb";
@import "layout/content";
@import "layout/footer";
@import "layout/header";
@import "layout/sidebar";
@import "layout/select2";
@import "layout/nav-tab";
@import "layout/popup";
@import "layout/table";
@import "layout/theme-settings";

/****** PAGES ******/
@import "pages/activites";
@import "pages/assets";
@import "pages/attendance";
@import "pages/chat";
@import "pages/call";
@import "pages/calendar";
@import "pages/contact";
@import "pages/components";
@import "pages/email";
@import "pages/error";
@import "pages/dashboard";
@import "pages/filemanager";
@import "pages/login";
@import "pages/loader";
@import "pages/faq";
@import "pages/employee";
@import "pages/project";
@import "pages/task";
@import "pages/page";
@import "pages/profile";
@import "pages/payroll";
@import "pages/knowledge";
@import "pages/subscriptions";
@import "pages/notification";
@import "pages/react";
@import "pages/material";
@import "pages/charts";
@import "pages/file-upload";
@import "pages/ribbon.scss";
@import "pages/timeline.scss";
@import "pages/form-wizard.scss";

.contact-info {
    .text-ellipsis {
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .contact-date {
        color: #888;
        font-size: 12px;
    }
}

.form-sort {
    position: relative;
}
.title-head .form-sort .list-view {
    background-color: #fff;
    border: 1px solid #e2e4e6;
    color: #7a7c7f;
    font-size: 14px;
    line-height: normal;
    padding: 10px 10px 10px 30px;
    text-decoration: none;
}

.form-sort i {
    height: 16px;
    left: 10px;
    position: absolute;
    top: 12px;
    width: 16px;
    z-index: 101;
}

#filter_search {
    margin-right: 15px;
    position: relative;
}

#filter_search:after {
    background: #d3d3d4;
    content: "";
    height: 25px;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
}

.title-head .add-btn {
    border-radius: 5px;
    margin-left: 10px;
}
.view-icons .btn-link.active-filter {
    background: #ff902f;
    border-color: #ff902f;
    color: #fff;
}